import { createApi } from '@reduxjs/toolkit/query/react';
import { IServiceCategory, ServiceCategoryMutationResponse } from './types';
import { endpoints, axiosBaseQuery } from 'tools';

export const serviceCategoriesApi = createApi({
  reducerPath: 'serviceCategoriesApi',
  baseQuery: axiosBaseQuery({ baseURL: endpoints.serviceCategories.root }),
  tagTypes: ['ServiceCategories', 'ServiceCategoryAddOn', 'Services'],
  endpoints: builder => ({
    getServiceCategories: builder.query<Record<string, IServiceCategory>, null>({
      query: () => ({
        url: '',
        method: 'get',
      }),
      providesTags: [{ type: 'ServiceCategories', id: 'LIST' }],
    }),
    getServiceCategory: builder.query<IServiceCategory, { id?: string; nameId?: string }>({
      query: ({ id = '', nameId = '' }) => ({
        url: endpoints.serviceCategories.getCategory,
        method: 'get',
        params: { id, nameId },
      }),
      providesTags: (result, _error) => {
        return [{ type: 'ServiceCategories', id: result?._id }];
      },
    }),
    createServiceCategory: builder.mutation<ServiceCategoryMutationResponse, IServiceCategory>({
      query: body => {
        return {
          url: '',
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'ServiceCategories', id: 'LIST' }],
    }),
    editServiceCategory: builder.mutation<ServiceCategoryMutationResponse, { body: any; id: string }>({
      query: ({ body, id }) => {
        return {
          url: endpoints.serviceCategories.singleResource.replace(':id', id),
          method: 'PATCH',
          data: body,
        };
      },
      invalidatesTags: (result, _error) => [
        {
          type: 'ServiceCategories',
          id: 'LIST',
        },
        { type: 'ServiceCategories', id: result?.serviceCategory._id },
      ],
    }),
    deleteServiceCategory: builder.mutation<{ message: string }, string>({
      query: id => ({
        url: endpoints.serviceCategories.singleResource.replace(':id', id),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'ServiceCategories', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetServiceCategoriesQuery,
  useGetServiceCategoryQuery,
  useCreateServiceCategoryMutation,
  useEditServiceCategoryMutation,
  useDeleteServiceCategoryMutation
} = serviceCategoriesApi;
