import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import appStateReducer from './reducers/app/appState';
import { RootState } from './utils/redux.types';
import { serviceCategoriesApi } from './api/serviceCategories/serviceCategories.api';
import { usersApi } from './api/users/users.api';
import { servicesApi } from './api/services';
import { appointmentsApi } from './api/appointments';
import { productCategoriesApi } from './api/productCategory';
import { productsApi } from './api/products';
import { galleryApi } from './api/gallery';
import { appSettingsApi } from './api/appSettings';
import bookingStateReducer from 'features/Appointments/Booking/BookingSteps/store';

const rootReducer = combineReducers({
  [servicesApi.reducerPath]: servicesApi.reducer,
  [serviceCategoriesApi.reducerPath]: serviceCategoriesApi.reducer,
  [productCategoriesApi.reducerPath]: productCategoriesApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [appointmentsApi.reducerPath]: appointmentsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [appStateReducer.name]: appStateReducer.reducer,
  [bookingStateReducer.name]: bookingStateReducer.reducer,
  [galleryApi.reducerPath]: galleryApi.reducer,
  [appSettingsApi.reducerPath]: appSettingsApi.reducer,
});

// TODO: Move redux api's into respective feature folders

// TODO: Persist state with Redux-Persist
const setupStore = (preloadedState?: PreloadedState<RootState>): any => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({}).concat([
        usersApi.middleware,
        serviceCategoriesApi.middleware,
        servicesApi.middleware,
        appointmentsApi.middleware,
        productCategoriesApi.middleware,
        productsApi.middleware,
        galleryApi.middleware,
        appSettingsApi.middleware,
      ]),
  });
};

const store = setupStore({});

export { setupStore, rootReducer, store };
