enum USER_PERMISSIONS {
  READ = 'read_user',
  CREATE = 'create_user',
  UPDATE = 'update_user',
  DELETE = 'delete_user',
}

enum SERVICE_CATEGORY_PERMISSIONS {
  READ = 'read_service_category',
  CREATE = 'create_service_category',
  UPDATE = 'update_service_category',
  DELETE = 'delete_service_category',
}

enum SERVICE_PERMISSIONS {
  READ = 'read_service',
  CREATE = 'create_service',
  UPDATE = 'update_service',
  DELETE = 'delete_service',
}

enum SCHEDULE_PERMISSIONS {
  READ = 'read_schedule',
  CREATE = 'create_schedule',
  UPDATE = 'update_schedule',
  DELETE = 'delete_schedule',
}

enum PRODUCT_CATEGORY_PERMISSIONS {
  READ = 'read_product_category',
  CREATE = 'create_product_category',
  UPDATE = 'update_product_category',
  DELETE = 'delete_product_category',
}

export {
  USER_PERMISSIONS,
  PRODUCT_CATEGORY_PERMISSIONS,
  SERVICE_CATEGORY_PERMISSIONS,
  SERVICE_PERMISSIONS,
  SCHEDULE_PERMISSIONS,
};
