import { FC, ReactNode } from 'react';
import styles from './index.module.scss';

const CardGrid: FC<{ children: ReactNode; type?: 'shop' | 'service' | 'category' }> = ({ children, type = 'service' }) => {
  const classes = `${styles.container} ${styles[`container__${type}`]}`;

  return <div className={classes}>{children}</div>;
};

export default CardGrid;
