import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IGetUsersQuery,
  IUser,
  IGetUsersResponse,
  IUserPermission,
  IUserMutationResponse,
  IUserAddress,
  IUserAddressMutationResponse,
} from './types';
import { endpoints, axiosBaseQuery } from 'tools';
import qs from 'qs';
import { IResponse } from '../types';

export const usersApi = createApi({
  reducerPath: 'UsersApi',
  baseQuery: axiosBaseQuery({ baseURL: endpoints.users.root }),
  tagTypes: ['Users', 'UserAddress'],
  endpoints: builder => ({
    registerUser: builder.mutation<IUser, Pick<IUser, 'firstName' | 'lastName' | 'email'>>({
      query: body => {
        return {
          url: endpoints.users.register,
          method: 'POST',
          data: body,
        };
      },
    }),
    isRegistered: builder.query<{ message: string }, string>({
      query: email => {
        return {
          url: endpoints.users.exists.replace(':email', email),
          method: 'GET',
        };
      },
    }),
    getLoggedInUser: builder.query<IUser, null>({
      query: () => ({
        url: endpoints.users.getLoggedInUser,
        method: 'GET',
      }),
      providesTags: [{ type: 'Users', id: 'loggedInUser' }],
    }),
    getUsers: builder.query<IGetUsersResponse, IGetUsersQuery>({
      query: args => ({
        url: endpoints.users.getAllUsers,
        method: 'GET',
        params: args,
      }),
      serializeQueryArgs: args => {
        return qs.stringify(args, { arrayFormat: 'repeat' });
      },
    }),
    getUser: builder.query<IUser, string>({
      query: id => ({
        url: endpoints.users.singleResource.replace(':id', id),
        method: 'GET',
      }),
      providesTags: (_result, _error, id) => [{ type: 'Users', id }],
    }),
    editUserProfile: builder.mutation<IUser, IUser>({
      query: body => ({
        url: endpoints.users.getLoggedInUser,
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: [{ type: 'Users', id: 'loggedInUser' }],
    }),
    getPermissions: builder.query<Record<string, IUserPermission[]>, null>({
      query: () => ({
        url: endpoints.users.getPermissions,
        method: 'GET',
      }),
    }),
    manageUser: builder.mutation<IUserMutationResponse, { role: 'admin' | 'basic'; permissions: string[]; id: string }>({
      query: body => ({
        url: endpoints.users.manageUser,
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: result => [{ type: 'Users', id: result?.user._id }],
    }),
    createAddress: builder.mutation<
      IUserAddressMutationResponse,
      Pick<IUserAddress, 'streetName' | 'streetNumber' | 'postCode' | 'city' | 'country'>
    >({
      query: body => ({
        url: endpoints.users.address,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'UserAddress', id: 'LIST' }],
    }),
    getAddresses: builder.query<Record<string, IUserAddress>, null>({
      query: () => ({
        url: endpoints.users.address,
        method: 'GET',
      }),
      providesTags: [{ type: 'UserAddress', id: 'LIST' }],
    }),
    editAddress: builder.mutation<IUserAddressMutationResponse, IUserAddress>({
      query: body => ({
        url: endpoints.users.address,
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: [{ type: 'UserAddress', id: 'LIST' }],
    }),
    deleteAddress: builder.mutation<IResponse, { _id: string }>({
      query: ({ _id }) => ({
        url: endpoints.users.address,
        method: 'DELETE',
        data: {
          _id,
        },
      }),
      invalidatesTags: [{ type: 'UserAddress', id: 'LIST' }],
    }),
    deleteAccount: builder.mutation<IResponse, null>({
      query: () => ({
        url: endpoints.users.getLoggedInUser,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users', 'UserAddress'],
    }),
    changeEmail: builder.mutation<IResponse, { email: string }>({
      query: body => ({
        url: endpoints.users.email,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [{ type: 'Users', id: 'loggedInUser' }],
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLazyIsRegisteredQuery,
  useGetLoggedInUserQuery,
  useLazyGetLoggedInUserQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useEditUserProfileMutation,
  useGetPermissionsQuery,
  useManageUserMutation,
  useCreateAddressMutation,
  useGetAddressesQuery,
  useEditAddressMutation,
  useDeleteAddressMutation,
  useDeleteAccountMutation,
  useChangeEmailMutation
} = usersApi;
