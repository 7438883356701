import { Result, Button } from 'antd';
import { FC } from 'react';
import { useLocation, useNavigate, useRouteError } from 'react-router-dom';
import { getErrorDetails } from './functions';
import { urls } from 'global-constants';
import styles from './ErrorPage.module.scss';
import { ErrorStatus } from './constants';
import LoginButton from 'features/Authentication/LoginButton';

// TODO: Change prop to accept key and not require import in components

const ErrorPage: FC<{ status?: ErrorStatus }> = ({ status = ErrorStatus.NOT_FOUND }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const error = useRouteError();

  console.error(error);

  const onClick = (): void => {
    if (
      location.pathname.includes(urls.admin.root) &&
      !(location.pathname.includes(urls.admin.root) && status === ErrorStatus.UNAUTHORIZED)
    ) {
      return navigate(urls.admin.root, { replace: true });
    }
    navigate(urls.client.root, { replace: true });
  };

  const extraButtons = [
    <Button type="primary" onClick={onClick} key="home">
      Home
    </Button>,
  ];

  if (status === ErrorStatus.UNAUTHENTICATED) {
    extraButtons.unshift(<LoginButton key="login" />);
  }

  const { appIcon, message } = getErrorDetails(status);
  return <Result className={styles['result-error']} title={status} subTitle={message} icon={appIcon} extra={extraButtons} />;
};

export default ErrorPage;
