import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery, endpoints } from 'tools';
import { IResponse } from '../types';
import { IAddGalleryItemBody, IGalleryItem, IEditGAlleryItemsBody } from './types';

export const galleryApi = createApi({
  reducerPath: 'galleryApi',
  baseQuery: axiosBaseQuery({ baseURL: endpoints.gallery.root }),
  tagTypes: ['Gallery'],
  endpoints: builder => ({
    addGalleryItems: builder.mutation<IResponse, IAddGalleryItemBody>({
      query: body => {
        return {
          url: '',
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'Gallery', id: 'LIST' }],
    }),
    editGalleryItems: builder.mutation<IResponse, IEditGAlleryItemsBody>({
      query: body => {
        return {
          url: '',
          method: 'PUT',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'Gallery', id: 'LIST' }],
    }),
    getGalleryItems: builder.query<Record<string, IGalleryItem>, null>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      providesTags: [{ type: 'Gallery', id: 'LIST' }],
    }),
    deleteGalleryItems: builder.mutation<IResponse, { items: string[] }>({
      query: body => ({
        url: '',
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: [{ type: 'Gallery', id: 'LIST' }],
    }),
  }),
});

export const { useAddGalleryItemsMutation, useEditGalleryItemsMutation, useGetGalleryItemsQuery, useDeleteGalleryItemsMutation } = galleryApi;
