import { createSlice } from '@reduxjs/toolkit';
import { IBookingState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IStepIndices } from '../types';
import { MediaType } from 'components/Uploader/types';
import { loadState } from './localStorage';
import { ITimeslot } from 'redux/api/appointments/types';

const initialAppState: IBookingState = {
  variation: { id: '', requiredProducts: {}, price: 0, duration: 0 },
  extras: {},
  current: 0,
  extraProductsValidation: {},
  variationProductsValidation: {},
  comments: {
    text: '',
    images: [],
  },
  steps: {
    schedule: 0,
    comments: 1,
    payment: 2,
  },
  schedule: {},
  completedSteps: {},
  totalDuration: 0,
  totalPrice: 0,
};

const bookingStateSlice = createSlice({
  name: 'bookingState',
  initialState: { ...(loadState() ?? initialAppState) },
  reducers: {
    resetState: state => {
      state = { ...initialAppState };
      return state;
    },
    setVariation: (state, action: PayloadAction<{ id: string; duration: number; price: number }>) => {
      if (state.variation.id !== action.payload.id || !state.variation.id) {
        state = { ...initialAppState, variation: { ...initialAppState.variation, ...action.payload } };
      }
      state.variation.id = action.payload.id;
      return state;
    },
    addExtra: (state, action: PayloadAction<{ id: string; duration: number; price: number }>) => {
      if (state.extras[action.payload.id]?.duration !== action.payload.duration) {
        state.schedule = {};
      }

      state.extras[action.payload.id] = {
        ...action.payload,
        requiredProducts: {},
      };

      return state;
    },
    removeExtra: (state, action: PayloadAction<string>) => {
      const { [action.payload]: removedExtra, ...newExtras } = state.extras;
      if (removedExtra?.duration) {
        state.schedule = {};
      }
      state.extras = newExtras;
      return state;
    },
    setVariationClientProduct: (
      state,
      action: PayloadAction<{ reqProduct: string; clientProducts?: boolean; completed?: boolean }>,
    ) => {
      const { reqProduct, ...rest } = action.payload;
      state.variation.requiredProducts[reqProduct] = {
        ...(state.variation.requiredProducts[reqProduct] ?? { productItems: {} }),
        ...rest,
      };
      return state;
    },
    setExtraClientProduct: (
      state,
      action: PayloadAction<{ extra: string; reqProduct: string; clientProducts?: boolean; completed?: boolean }>,
    ) => {
      const { reqProduct, extra, ...rest } = action.payload;
      state.extras[extra].requiredProducts[reqProduct] = {
        ...(state.extras[extra].requiredProducts[reqProduct] ?? { productItems: {} }),
        ...rest,
      };
    },
    updateVariationProductsValidation: (state, action: PayloadAction<{ reqProduct: string; completed: boolean }>) => {
      state.variationProductsValidation = {
        ...state.variationProductsValidation,
        [action.payload.reqProduct]: action.payload.completed,
      };
    },
    updateExtraProductsValidation: (state, action: PayloadAction<{ extra: string; reqProduct: string; completed: boolean }>) => {
      const { reqProduct, extra, completed } = action.payload;

      state.extraProductsValidation[extra] = {
        ...(state.extraProductsValidation[extra] ?? {}),
        [reqProduct]: completed,
      };
      return state;
    },
    updateVariationProduct: (
      state,
      action: PayloadAction<{ reqProduct: string; productItem: string; change: number; price: number; discount: number }>,
    ) => {
      if (!state.variation.requiredProducts[action.payload.reqProduct]) {
        state.variation.requiredProducts[action.payload.reqProduct] = {
          productItems: {},
          clientProducts: false,
        };
      }
      state.variation.requiredProducts[action.payload.reqProduct].productItems[action.payload.productItem] = {
        quantity:
          (state.variation.requiredProducts[action.payload.reqProduct].productItems[action.payload.productItem]?.quantity ?? 0) +
          action.payload.change,
        price: action.payload.price,
        discount: action.payload.discount,
      };
      return state;
    },
    updateExtraProduct: (
      state,
      action: PayloadAction<{
        extra: string;
        reqProduct: string;
        productItem: string;
        change: number;
        price: number;
        discount: number;
      }>,
    ) => {
      if (!state.extras[action.payload.extra].requiredProducts[action.payload.reqProduct]) {
        state.extras[action.payload.extra].requiredProducts[action.payload.reqProduct] = {
          productItems: {},
          clientProducts: false,
        };
      }
      state.extras[action.payload.extra].requiredProducts[action.payload.reqProduct].productItems[action.payload.productItem] = {
        quantity:
          (state.extras[action.payload.extra].requiredProducts[action.payload.reqProduct].productItems[action.payload.productItem]
            ?.quantity ?? 0) + action.payload.change,
        price: action.payload.price,
        discount: action.payload.discount,
      };
      return state;
    },
    setHairTexture: (state, action: PayloadAction<{ id: string; duration?: number; price?: number }>) => {
      state.hairTexture = action.payload;
    },
    setSchedule: (state, action: PayloadAction<{ date: string; timeslot: ITimeslot }>) => {
      state.schedule = {
        ...action.payload,
      };
    },
    clearSchedule: state => {
      state.schedule = {};
    },
    changeCurrent: (state, action: PayloadAction<number>) => {
      const stepLength = Object.keys(state.steps).length;
      const temp = state.current + action.payload;
      if (stepLength > temp && temp >= 0) {
        state.current = temp;
      }
    },
    setCommentText: (state, action: PayloadAction<string>) => {
      state.comments.text = action.payload;
    },
    setCommentImages: (state, action: PayloadAction<MediaType[]>) => {
      state.comments.images = action.payload;
    },
    setCurrent: (state, action: PayloadAction<number>) => {
      if (action.payload >= 0 && action.payload <= Object.keys(state.steps).length) {
        state.current = action.payload;
      }
      return state;
    },
    setSteps: (state, action: PayloadAction<IStepIndices>) => {
      state.steps = action.payload;
      return state;
    },
    addCompletedStep: (state, action: PayloadAction<keyof IStepIndices>) => {
      const step = {
        [action.payload]: state.steps[action.payload],
      };

      const temp = { ...state.completedSteps, ...step };
      state.completedSteps = { ...temp };
      return state;
    },
    removeCompletedStep: (state, action: PayloadAction<keyof IStepIndices>) => {
      if (Object.prototype.hasOwnProperty.call(state.completedSteps, action.payload)) {
        const temp: Partial<IStepIndices> = {};
        Object.keys(state.completedSteps).forEach(step => {
          if ((state.completedSteps[step as keyof IStepIndices] ?? 0) < (state.completedSteps[action.payload] ?? 0)) {
            temp[step as keyof IStepIndices] = state.completedSteps[step as keyof IStepIndices];
          }
        });

        state.completedSteps = { ...temp };
      }
      return state;
    },
    setBillingAddress: (state, action: PayloadAction<string>) => {
      state.billingAddress = action.payload;
    },
  },
});

export const {
  addExtra,
  removeExtra,
  changeCurrent,
  setCurrent,
  setSteps,
  setVariation,
  setExtraClientProduct,
  setVariationClientProduct,
  setCommentText,
  setCommentImages,
  updateExtraProduct,
  updateVariationProduct,
  addCompletedStep,
  removeCompletedStep,
  updateExtraProductsValidation,
  updateVariationProductsValidation,
  setHairTexture,
  setSchedule,
  clearSchedule,
  resetState,
  setBillingAddress
} = bookingStateSlice.actions;

export default bookingStateSlice;
