import { urls } from 'global-constants';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { SuspendElement } from 'routes/SuspendElement';

const PaymentPage = lazy(async () => await import('features/Payment'));

const paymentRoutes: RouteObject[] = [
  {
    path: urls.client.payment.root,
    element: <SuspendElement element={<PaymentPage />} isProtected />,
  },
];

export default paymentRoutes;
