import { Form, Input, Button, Typography, Checkbox, message, Switch } from 'antd';
import { FC, useState } from 'react';
import { IUser } from 'redux/api/users/types';
import { useRegisterUserMutation, useEditUserProfileMutation } from 'redux/api/users/users.api';
import AuthResult from '../AuthResult/AuthResult';
import { sendLoginEmail } from '../functions';
import { IFeedback } from './../types';
import { IRegistrationFormProps } from './types';
import { genericRule } from './constants';
import { HasAccess } from 'components';

const { Title, Text } = Typography;

// TODO: move address to user profile, billing address and home addresses.
const RegistrationForm: FC<IRegistrationFormProps> = ({ toggleForm, user }) => {
  const [registerUser, { isLoading: isCreating }] = useRegisterUserMutation();
  const [editUserProfile, { isLoading: isEditing }] = useEditUserProfileMutation();
  const [submitted, setSubmitted] = useState(false);
  const [feedback, setFeedback] = useState<IFeedback>({ status: 201 });

  const isLoading = isCreating || isEditing;

  const [form] = Form.useForm();

  const handleSubmit = (values: IUser): void => {
    if (!user) {
      registerUser(values)
        .unwrap()
        .then(_res => {
          sendLoginEmail({ email: values.email })
            .then(_res => {
              setFeedback({
                status: 201,
              });
            })
            .catch(_e => setFeedback({ status: 500 }));
        })
        .catch((e: { status: 409 | 500 }) => {
          console.error(e);
          setFeedback({
            status: e.status,
            extras: e.status === 409 ? <Button onClick={toggleForm}>Sign In</Button> : undefined,
          });
        })
        .finally(() => setSubmitted(true));
    } else {
      editUserProfile(values)
        .unwrap()
        .then(() => {
          void message.success('Profile updated!');
        })
        .catch(e => {
          void message.error('Error saving profile changes, please refresh and try again!');
          console.log(e);
        })
        .finally(() => toggleForm());
    }
  };

  if (submitted) {
    return <AuthResult status={feedback.status} extras={feedback.extras} />;
  }

  return (
    <Form name="registration form" form={form} layout="vertical" onFinish={handleSubmit} initialValues={user}>
      <Title level={3}>{!user ? 'Sign Up' : 'Edit Profile'}</Title>
      <Form.Item label="First name" name="firstName" rules={genericRule}>
        <Input type="string" />
      </Form.Item>
      <Form.Item label="Last name" name="lastName" rules={genericRule}>
        <Input type="string" />
      </Form.Item>
      {!user && (
        <Form.Item label="Email" name="email" rules={genericRule}>
          <Input type="email" />
        </Form.Item>
      )}
      {user && (
        <>
          <HasAccess>
            <Form.Item label="Receive Admin Emails" name="notify" valuePropName="checked">
              <Switch />
            </Form.Item>
          </HasAccess>
          <Form.Item label="Phone Number" name="phoneNumber">
            <Input />
          </Form.Item>
        </>
      )}
      {!user && (
        <Form.Item
          name="agreement"
          rules={[
            {
              validator: async (_, value) =>
                value ? await Promise.resolve() : await Promise.reject(new Error('Accept the terms and conditions to continue.')),
            },
          ]}
          valuePropName="checked"
          required>
          <Checkbox>
            I have read and accepted the
            <a href="" target="_blank" style={{ padding: 0 }}>
              {' '}
              terms and conditions.
            </a>
          </Checkbox>
        </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading} block disabled={isLoading}>
          {!user ? 'Create Account' : 'Save Changes'}
        </Button>
      </Form.Item>

      {!user && (
        <Text>
          Already have an account?
          <Button type="text" onClick={toggleForm}>
            Sign in.
          </Button>
        </Text>
      )}
    </Form>
  );
};

export default RegistrationForm;
