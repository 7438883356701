import { List, Modal } from 'antd';
import { FC, useState } from 'react';
import { IModalListProps } from './types';
import styles from './index.module.scss';
import Container from 'components/Container';
import ModalTitle from 'components/Typography/ModalTitle';

const ModalList: FC<IModalListProps> = ({ listItems }) => {
  const [modal, setModal] = useState({
    visible: false,
    label: '',
  });

  const onClick = (label: string): void => {
    setModal({ visible: true, label });
  };

  const onCancel = (): void => {
    setModal({ visible: false, label: '' });
  };

  const items = listItems(onCancel);

  return (
    <Container>
      <List
        bordered
        className={styles.list}
        dataSource={Object.values(items)}
        renderItem={item => (
          <List.Item onClick={() => onClick(item.label)} className={styles.listItem}>
            {item.label}
          </List.Item>
        )}
      />

      {modal.visible && (
        <Modal footer={null} open={modal.visible} onCancel={onCancel} title={<ModalTitle text={modal.label} />}>
          {items[modal.label].element}
        </Modal>
      )}
    </Container>
  );
};

export default ModalList;
