const BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  services: {
    root: '/services',
    variation: '/variation',
  },
  serviceCategories: {
    root: '/service-categories',
    singleResource: '/:id',
    getCategory: '/category',
    addOn: '/add-on',
  },
  users: {
    root: '/users',
    register: '/register',
    exists: '/exists/:email',
    getLoggedInUser: '/me',
    getAllUsers: '?',
    singleResource: '/:id',
    getPermissions: '/permissions',
    manageUser: '/manage',
    address: '/address',
    email: '/email',
  },
  appointments: {
    root: '/appointments',
    table: '/table',
    schedule: '/schedule',
    settings: '/settings',
    hairTexture: '/hair-texture',
    adminAction: '/admin-action',
    clientAction: '/client-action',
  },
  productCategories: {
    root: '/product-categories',
    getCategory: '/category',

    subcategories: '/subcategory',
  },
  products: {
    root: '/product',
    singleResource: '/:id',
    attributes: '/attributes',
    attributeOptions: '/attributes/options',
    items: '/items',
    item: '/items/item',
    index: '/index',
  },
  gallery: {
    root: '/gallery',
  },
  appSettings: {
    root: '/app-settings',
    homePage: '/home-page',
    appointment: '/appointment',
  },
};

const servicesRendered = [
  { serviceType: 'Twists', image: 'https://i.pinimg.com/736x/38/55/0d/38550d8cf648f269b25c27e5414e6970.jpg' },
  {
    serviceType: 'Corn rows',
    image:
      'https://images.unsplash.com/photo-1583284791098-29f95de7190b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGJyYWlkc3xlbnwwfDB8MHx8&auto=format&fit=crop&w=800&q=60',
  },
  {
    serviceType: 'Extras',
    image:
      'https://media.glamour.com/photos/5f5519f4f734b199c8e5de98/master/w_1080,h_1350,c_limit/69477879_2471045339794881_8161559322941818829_n.jpg',
  },
];

export { BACKEND_URL, endpoints, servicesRendered };
