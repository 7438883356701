import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { FC } from 'react';

const VisibleTag: FC<{ visible: boolean }> = ({ visible }) => (
  <Tag color={visible ? '#206a00' : '#ff0003'} style={{ height: '100%' }}>
    {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
  </Tag>
);

export default VisibleTag;
