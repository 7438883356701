import { bugFixing, mailSent, signIn, signUp } from 'assets/svg';

const authResults = {
  200: {
    icon: mailSent,
    title: 'Sign in link sent!',
    subTitle: 'Please check your email for the sign in link.',
  },
  201: {
    icon: mailSent,
    title: 'Registration Completed!',
    subTitle: 'Please check your email for the sign in link.',
  },
  404: {
    icon: signUp,
    title: 'No account found',
    subTitle: 'This email address is not registered.',
  },
  409: {
    icon: signIn,
    title: 'Email already exists.',
    subTitle: 'An account already exists with this email address.',
  },
  500: {
    icon: bugFixing,
    title: 'An error occurred',
    subTitle: 'Please refresh the page and try again',
  },
};

export { authResults };
