import { Result } from 'antd';
import { FC } from 'react';
import { IAuthResultProps } from './types';
import { authResults } from './constants';

const AuthResult: FC<IAuthResultProps> = ({ extras, status }) => {
  return <Result {...authResults[status]} extra={extras} />;
};

export default AuthResult;
