import { createApi } from '@reduxjs/toolkit/query/react';
import { endpoints, axiosBaseQuery } from 'tools';
import {
  DayOfTheWeek,
  IDefaultScheduleMutationResponse,
  IHairTexture,
  IHairTextureResponse,
  ISaveScheduleParams,
  ISchedule,
  IGetScheduleParams,
  ScheduleType,
  IAppointmentMutationResponse,
  ICreateAppointmentBody,
  IGetAppointmentsTableQuery,
  IGetAppointmentsTableResponse,
  IAppointment,
  IAppointmentStateActionParams,
  IGetAppointmentsListQuery,
} from './types';
import { IResponse } from '../types';

export const appointmentsApi = createApi({
  reducerPath: 'appointmentsApi',
  baseQuery: axiosBaseQuery({ baseURL: endpoints.appointments.root }),
  tagTypes: ['Appointments', 'Settings', 'Schedule', 'HairTexture'],
  endpoints: builder => ({
    createAppointment: builder.mutation<IAppointmentMutationResponse, ICreateAppointmentBody>({
      query: body => ({
        url: '',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [
        { type: 'Appointments', id: 'client_list' },
        { type: 'Appointments', id: 'admin_list' },
      ],
    }),
    getAppointmentTable: builder.query<IGetAppointmentsTableResponse, IGetAppointmentsTableQuery>({
      query: params => ({
        url: endpoints.appointments.table,
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'Appointments', id: 'admin_list' }],
    }),
    getAppointment: builder.query<IAppointment, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { id }) => [{ type: 'Appointments', id }],
    }),
    getAppointments: builder.query<Array<Partial<IAppointment>>, IGetAppointmentsListQuery>({
      query: params => ({
        url: '',
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'Appointments', id: 'client_list' }],
    }),
    putAdminAppointmentAction: builder.mutation<IAppointmentMutationResponse, IAppointmentStateActionParams>({
      query: body => ({
        url: endpoints.appointments.adminAction,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Appointments', id },
        { type: 'Appointments', id: 'admin_list' },
      ],
    }),
    putClientAppointmentAction: builder.mutation<IAppointmentMutationResponse, IAppointmentStateActionParams>({
      query: body => ({
        url: endpoints.appointments.clientAction,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Appointments', id },
        { type: 'Appointments', id: 'client_list' },
      ],
    }),
    saveSchedule: builder.mutation<IDefaultScheduleMutationResponse, ISaveScheduleParams>({
      query: body => ({
        url: endpoints.appointments.schedule,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Schedule', id: 'LIST' }],
    }),
    getSchedule: builder.query<Record<DayOfTheWeek | string, ISchedule>, IGetScheduleParams>({
      query: ({ paramId = '', ...rest }) => ({
        url: `${endpoints.appointments.schedule}/${paramId}`,
        method: 'GET',
        params: {
          ...rest,
        },
      }),
      providesTags: [{ type: 'Schedule', id: 'LIST' }],
    }),

    deleteSchedule: builder.mutation<IResponse, { type: ScheduleType; date: string }>({
      query: body => ({
        url: endpoints.appointments.schedule,
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: [{ type: 'Schedule', id: 'LIST' }],
    }),
    createHairTexture: builder.mutation<IHairTextureResponse, Partial<IHairTexture>>({
      query: body => ({
        url: endpoints.appointments.hairTexture,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'HairTexture', id: 'LIST' }],
    }),
    editHairTexture: builder.mutation<IHairTextureResponse, Partial<IHairTexture>>({
      query: body => ({
        url: endpoints.appointments.hairTexture,
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: [{ type: 'HairTexture', id: 'LIST' }],
    }),
    deleteHairTexture: builder.mutation<IHairTextureResponse, Partial<IHairTexture>>({
      query: body => ({
        url: `${endpoints.appointments.hairTexture}/${body._id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'HairTexture', id: 'LIST' }],
    }),
    getHairTextures: builder.query<Record<string, IHairTexture>, null>({
      query: () => ({
        url: endpoints.appointments.hairTexture,
        method: 'GET',
      }),
      providesTags: [{ type: 'HairTexture', id: 'LIST' }],
    }),
  }),
});

export const {
  useSaveScheduleMutation,
  useGetScheduleQuery,
  useDeleteScheduleMutation,
  useCreateHairTextureMutation,
  useGetHairTexturesQuery,
  useEditHairTextureMutation,
  useDeleteHairTextureMutation,
  useCreateAppointmentMutation,
  useGetAppointmentTableQuery,
  useGetAppointmentQuery,
  useGetAppointmentsQuery,
  usePutAdminAppointmentActionMutation,
  usePutClientAppointmentActionMutation,
} = appointmentsApi;
