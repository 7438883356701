import { urls } from 'global-constants';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { SuspendElement } from 'routes/SuspendElement';

const CategoryPage = lazy(async () => await import('./CategoryPage'));
const CategoryDetailsPage = lazy(async () => await import('./CategoryDetailsPage'));
const SubCategoryListPage = lazy(async () => await import('./SubCategoryListPage'));
const AttributeListPage = lazy(async () => await import('./AttributeListPage'));
const AttributeOptionsPage = lazy(async () => await import('./AttributeOptionsPage'));
const ProductListPage = lazy(async () => await import('./ProductListPage'));
const ProductDetailsPage = lazy(async () => await import('./ProductDetailsPage'));
const ProductIndexList = lazy(async () => await import('features/Products/Indexes/List'));

const shopRoutes: RouteObject[] = [
  {
    path: urls.admin.shop.root,
    element: <SuspendElement element={<CategoryPage />} />,
  },
  {
    path: urls.admin.shop.products,
    element: <SuspendElement element={<ProductListPage />} />,
  },
  {
    path: urls.admin.shop.subcategories,
    element: <SuspendElement element={<SubCategoryListPage />} />,
  },
  {
    path: urls.admin.shop.attributes,
    element: <SuspendElement element={<AttributeListPage />} />,
  },
  {
    path: urls.admin.shop.attributeOptions,
    element: <SuspendElement element={<AttributeOptionsPage />} />,
  },
  {
    path: urls.admin.shop.details,
    element: <SuspendElement element={<CategoryDetailsPage />} />,
  },
  {
    path: urls.admin.shop.productDetails,
    element: <SuspendElement element={<ProductDetailsPage />} />,
  },
  {
    path: urls.admin.shop.productIndex,
    element: <SuspendElement element={<ProductIndexList />} />,
  },
];

export default shopRoutes;
