import { RouteObject } from 'react-router-dom';
import { urls } from '../../global-constants';
import { lazy } from 'react';
import { SuspendElement } from '../../routes/SuspendElement';
import { ErrorPage } from 'components';
import { ErrorStatus } from 'components/AppError';
import profileRoutes from './Profile';
import shopRoutes from './Shop';
import serviceRoutes from './Services';
import appointmentRoutes from './Appointment';
import paymentRoutes from './Payment';
import galleryRoutes from './Gallery';

const Home = lazy(async () => await import('features/LandingScreen'));
const ServiceCategories = lazy(async () => await import('./Services/ServiceCategories'));
const ServiceCategoryClientDetails = lazy(async () => await import('./Services/ServiceCategoryDetails'));
const Faq = lazy(async () => await import('../client/Faq/Faq'));
const ProfileRoot = lazy(async () => await import('../../layout/client/Profile/index'));
const Continue = lazy(async () => await import('../../features/Authentication/Login/Continue'));
const Root = lazy(async () => await import('layout/client/Root'));

const clientRoutes: RouteObject[] = [
  {
    path: urls.client.root,
    element: <SuspendElement element={<Root />} />,
    children: [
      {
        path: urls.client.root,
        element: <SuspendElement element={<Home />} />,
      },

      {
        path: urls.client.services.root,
        element: <SuspendElement element={<ServiceCategories />} />,
      },
      {
        path: urls.client.services.categoryDetails,
        element: <SuspendElement element={<ServiceCategoryClientDetails />} />,
      },
      {
        path: urls.client.faq,
        element: <SuspendElement element={<Faq />} />,
      },
      {
        path: urls.client.profile.root,
        element: <SuspendElement isProtected element={<ProfileRoot />} />,
        children: [...profileRoutes],
      },
      {
        path: urls.client.finishAuth,
        element: <SuspendElement element={<Continue />} />,
      },
      ...shopRoutes,
      ...serviceRoutes,
      ...appointmentRoutes,
      ...paymentRoutes,
      ...galleryRoutes,
    ],
    errorElement: <ErrorPage status={ErrorStatus.ERROR} />,
  },
];

export default clientRoutes;
