const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

const addressDetailsPlaceholder = 'Search and select an address to fill this field';

const addressFields = [
  { name: 'city', label: 'City', autoComplete: 'address-level2' },
  { name: 'postCode', label: 'Post Code', autoComplete: 'postal-code' },
  { name: 'country', label: 'Country', autoComplete: 'country-name' },
];

const genericRule = [{ required: true }];

export { mapboxToken, addressDetailsPlaceholder, genericRule, addressFields };
