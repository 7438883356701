import type { MenuProps } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { urls } from 'global-constants';

const getMenuItems = (isAdmin?: boolean): MenuProps['items'] => [
  {
    key: isAdmin ? urls.admin.profile.root : urls.client.profile.root,
    label: 'Profile',
    icon: <UserOutlined />,
  },
  {
    key: 'logout',
    label: 'Logout',
    icon: <LogoutOutlined />,
  },
];

export { getMenuItems };
