import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import { SuspendElement } from '../../../routes/SuspendElement';
import { urls } from 'global-constants';

const Details = lazy(async () => await import('./Details/Details'));
const Address = lazy(async () => await import('features/Users/UserAddress/List'));

const profileRoutes: RouteObject[] = [
  {
    path: '',
    element: <SuspendElement isProtected spinner element={<Details />} />,
  },
  {
    path: urls.client.profile.address,
    element: <SuspendElement isProtected spinner element={<Address />} />,
  },
];

export default profileRoutes;
