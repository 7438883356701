import { createApi } from '@reduxjs/toolkit/query/react';
import { endpoints, axiosBaseQuery } from 'tools';
import {
  IProductAttribute,
  IProductAttributeMutationResponse,
  IProductAttributeOption,
  IProductAttributeOptionMutationResponse,
  IProductMutationResponse,
  IProduct,
  IProductItemResponse,
  IProductItem,
  IProductItemRequest,
  IGetProductItemsResponse,
  IProductIndex,
  IProductIndexResponse,
} from './types';
import { IResponse } from '../types';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: axiosBaseQuery({ baseURL: endpoints.products.root }),
  tagTypes: ['Product', 'ProductItem', 'ProductIndex', 'ProductAttribute', 'ProductAttributeOption', 'ProductCategory'],
  endpoints: builder => ({
    createProduct: builder.mutation<IProductMutationResponse, Partial<IProduct>>({
      query: body => {
        return {
          url: '',
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'Product', id: 'LIST' }],
    }),
    editProduct: builder.mutation<IProductMutationResponse, Partial<IProduct>>({
      query: body => {
        return {
          url: endpoints.products.singleResource.replace(':id', body._id ?? ''),
          method: 'PATCH',
          data: body,
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Product', id: arg._id },
        { type: 'Product', id: 'LIST' },
      ],
    }),
    getSingleProduct: builder.query<{ items: IProductItem[] } & IProduct, { id: string }>({
      query: ({ id }) => {
        return {
          url: endpoints.products.singleResource.replace(':id', id),
          method: 'GET',
        };
      },
      providesTags: (_result, _error, args) => [{ type: 'Product', id: args.id }],
    }),
    getProducts: builder.query<Array<Pick<IProduct, 'name' | 'visible' | 'nameId' | 'category' | 'visible' | '_id'>>, null>({
      query: () => {
        return {
          url: '',
          method: 'GET',
        };
      },
      providesTags: [{ type: 'Product', id: 'LIST' }],
    }),
    deleteProduct: builder.mutation<IProductMutationResponse, string>({
      query: id => {
        return {
          url: endpoints.products.singleResource.replace(':id', id ?? ''),
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Product', id: arg },
        { type: 'Product', id: 'LIST' },
      ],
    }),

    // Product Items
    createProductItem: builder.mutation<IProductItemResponse, Partial<IProductItem>>({
      query: body => {
        return {
          url: endpoints.products.items,
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'ProductItem', id: 'LIST' }],
    }),

    editProductItem: builder.mutation<IProductItemResponse, Partial<IProductItem>>({
      query: body => {
        return {
          url: endpoints.products.items,
          method: 'PATCH',
          data: body,
        };
      },
      invalidatesTags: (_result, _error, args) => [
        { type: 'ProductItem', id: 'LIST' },
        { type: 'ProductItem', id: args._id },
        { type: 'Product', id: args.parent as unknown as string },
      ],
    }),

    getProductItems: builder.query<IGetProductItemsResponse, IProductItemRequest>({
      query: args => {
        return {
          url: endpoints.products.items,
          method: 'GET',
          params: args,
        };
      },
      providesTags: [{ type: 'ProductItem', id: 'LIST' }],
    }),
    getProductItem: builder.query<IProductItem, { id: string }>({
      query: args => {
        return {
          url: endpoints.products.item,
          method: 'GET',
          params: args,
        };
      },
      providesTags: (_result, _error, args) => [{ type: 'ProductItem', id: args.id }],
    }),

    deleteProductItem: builder.mutation<IProductItemResponse, { _id: string; parent: string }>({
      query: body => {
        return {
          url: endpoints.products.items,
          method: 'DELETE',
          params: { id: body._id },
        };
      },
      invalidatesTags: (_result, _error, args) => [
        { type: 'ProductItem', id: 'LIST' },
        { type: 'ProductItem', id: args._id },
        { type: 'Product', id: args.parent },
      ],
    }),

    // Product Attributes
    createProductAttribute: builder.mutation<
      IProductAttributeMutationResponse,
      Pick<IProductAttribute, 'name' | 'filter' | 'score'>
    >({
      query: body => {
        return {
          url: endpoints.products.attributes,
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'ProductAttribute', id: 'LIST' }],
    }),
    editProductAttribute: builder.mutation<
      IProductAttributeMutationResponse,
      { name: string; id: string; filter: boolean; score: number }
    >({
      query: body => {
        return {
          url: endpoints.products.attributes,
          method: 'PATCH',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'ProductAttribute', id: 'LIST' }, 'ProductCategory'],
    }),
    getProductAttributes: builder.query<IProductAttribute[], null>({
      query: () => {
        return {
          url: endpoints.products.attributes,
          method: 'GET',
        };
      },
      providesTags: [{ type: 'ProductAttribute', id: 'LIST' }],
    }),

    deleteProductAttribute: builder.mutation<IProductAttributeMutationResponse, string>({
      query: id => {
        return {
          url: endpoints.products.attributes,
          method: 'DELETE',
          params: {
            id,
          },
        };
      },
      invalidatesTags: [{ type: 'ProductAttribute', id: 'LIST' }, 'ProductCategory', 'Product'],
    }),

    // Attribute Options
    createProductAttributeOption: builder.mutation<
      IProductAttributeOptionMutationResponse,
      { name: string; attributeId: string; score: number }
    >({
      query: body => {
        return {
          url: endpoints.products.attributeOptions,
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: (_result, _error, arg) => [{ type: 'ProductAttributeOption', id: arg.attributeId }],
    }),
    editProductAttributeOption: builder.mutation<
      IProductAttributeMutationResponse,
      { name: string; id: string; attributeId: string; score: number }
    >({
      query: body => {
        return {
          url: endpoints.products.attributeOptions,
          method: 'PATCH',
          data: body,
        };
      },
      invalidatesTags: (_result, _error, arg) => [{ type: 'ProductAttributeOption', id: arg.attributeId }],
    }),
    getProductAttributeOptions: builder.query<Record<string, IProductAttributeOption[]>, { ids?: string[] }>({
      query: params => {
        return {
          url: endpoints.products.attributeOptions,
          method: 'GET',
          params,
        };
      },
      providesTags: (result, _error) =>
        result ? Object.keys(result).map(id => ({ type: 'ProductAttributeOption', id })) : ['ProductAttributeOption'],
    }),
    deleteProductAttributeOption: builder.mutation<IResponse, { id: string; attributeId: string }>({
      query: body => {
        return {
          url: endpoints.products.attributeOptions,
          method: 'DELETE',
          params: { id: body.id },
        };
      },
      invalidatesTags: (_result, _error, arg) => [{ type: 'ProductAttributeOption', id: arg.attributeId }, 'Product'],
    }),

    // Product Index
    createProductIndex: builder.mutation<IProductIndexResponse, IProductIndex>({
      query: body => ({
        url: endpoints.products.index,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'ProductIndex', id: 'List' }],
    }),
    getProductIndexes: builder.query<Record<string, IProductIndex>, null>({
      query: () => ({
        url: endpoints.products.index,
        method: 'GET',
      }),
      providesTags: [{ type: 'ProductIndex', id: 'List' }],
    }),
    editProductIndex: builder.mutation<IProductIndexResponse, IProductIndex>({
      query: body => ({
        url: endpoints.products.index,
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: [{ type: 'ProductIndex', id: 'List' }],
    }),
  }),
});

export const {
  useCreateProductAttributeMutation,
  useEditProductAttributeMutation,
  useGetProductAttributesQuery,
  useCreateProductAttributeOptionMutation,
  useEditProductAttributeOptionMutation,
  useGetProductAttributeOptionsQuery,
  useCreateProductMutation,
  useEditProductMutation,
  useGetProductsQuery,
  useGetSingleProductQuery,
  useDeleteProductMutation,
  useCreateProductItemMutation,
  useEditProductItemMutation,
  useGetProductItemsQuery,
  useGetProductItemQuery,
  useDeleteProductItemMutation,
  useDeleteProductAttributeMutation,
  useDeleteProductAttributeOptionMutation,
  useCreateProductIndexMutation,
  useGetProductIndexesQuery,
  useEditProductIndexMutation,
} = productsApi;
