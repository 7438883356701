export enum COLORS {
  GREEN = '#109c3e',
  PINK = '#fb6b90',
  BLUE = '#1271de',
  RED = '#ff0000',
  GREY = '#b3b5b4',
  LIME_GREEN = '#c8e339',
  OLIVE_GREEN = '#787D33',
  ORANGE = '#ff9100',
}
