import { FC, ReactElement, ReactNode } from 'react';

const Wrapper: FC<{ children: ReactNode | ReactElement }> = ({ children }) => {
  return (
    <div
      className="display-flex flex-1 margin-auto width-100-percent justify-content-center align-items-center"
      style={{ backgroundColor: 'var(--app-background-color)' }}>
      {children}
    </div>
  );
};

export default Wrapper;
