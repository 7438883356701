import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery, endpoints } from 'tools';
import { IHomePageSettingsBody, IHomePageSettingsModel, IAppointmentSettings } from './types';
import { IResponse } from '../types';

export const appSettingsApi = createApi({
  reducerPath: 'appSettingsApi',
  baseQuery: axiosBaseQuery({ baseURL: endpoints.appSettings.root }),
  tagTypes: ['AppSettings'],
  endpoints: builder => ({
    saveHomePage: builder.mutation<IResponse, Partial<IHomePageSettingsBody>>({
      query: body => {
        return {
          url: endpoints.appSettings.homePage,
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'AppSettings', id: 'HomePage' }],
    }),
    getHomePage: builder.query<IHomePageSettingsModel, null>({
      query: () => {
        return {
          url: endpoints.appSettings.homePage,
          method: 'GET',
        };
      },
      providesTags: [{ type: 'AppSettings', id: 'HomePage' }],
    }),
    saveAppointmentSettings: builder.mutation<IResponse, Partial<IAppointmentSettings>>({
      query: body => ({
        url: endpoints.appSettings.appointment,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'AppSettings', id: 'Appointments' }],
    }),
    getAppointmentSettings: builder.query<IAppointmentSettings, null>({
      query: () => {
        return {
          url: endpoints.appSettings.appointment,
          method: 'GET',
        };
      },
      providesTags: [{ type: 'AppSettings', id: 'Appointments' }],
    }),
  }),
});

export const {
  useSaveHomePageMutation,
  useGetHomePageQuery,
  useSaveAppointmentSettingsMutation,
  useGetAppointmentSettingsQuery,
} = appSettingsApi;
