import { FC } from 'react';
import { Typography, Divider } from 'antd';
import styles from './displayCard.module.scss';
import classNames from 'classnames';
import noImage from 'assets/no_image.jpg';
import VisibleTag from 'components/VisibleTag';
import { isAdminPath } from 'utils/isAdminPath';
import { IDisplayCardProps } from './types';

const { Title } = Typography;

const DisplayCard: FC<IDisplayCardProps> = ({ title, image, children, onClick, visible, ...rest }) => {
  const componentClasses = classNames(styles.container, rest.className, styles.container__categoryAspectRatio);

  delete rest.className;

  return (
    <div className={componentClasses} {...rest}>
      <div onClick={onClick ?? undefined}>
        <div className={styles.categoryImageContainer}>
          <img src={image ?? noImage} className={styles.image} />
        </div>

        <div className={styles.title} style={{ display: title ? 'flex' : 'none' }}>
          <Title level={4} className={styles.title__category} ellipsis>
            {title}
          </Title>
          {isAdminPath() && <VisibleTag visible={visible} />}
        </div>
      </div>
      {children}

      <Divider className={styles.divider} />
    </div>
  );
};

export default DisplayCard;
