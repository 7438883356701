import { FC, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Modal, message, Button } from 'antd';
import type { UploadFile, UploadProps, RcFile } from 'antd/es/upload/interface';
import { UploaderProps } from './types';
import { getBase64 } from './functions';

const Uploader: FC<UploaderProps> = ({
  accept,
  text = 'Upload',
  maxCount,
  fileList,
  setFileList,
  multiple = false,
  listType = 'picture',
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [uploading, setUploading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const handlePreview = async (file: UploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    const fileUrl = file.url as string;

    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || fileUrl.substring(fileUrl.lastIndexOf('/') + 1));
  };

  const handleCancel = (): void => setPreviewOpen(false);

  const disabled = (!!maxCount && fileList.length >= maxCount) || uploading;

  const props: UploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file, files) => {
      if (file.uid !== files[0].uid) {
        return false;
      }

      setUploading(true);
      const list: any = [];

      if (maxCount && [...fileList, ...files].length > maxCount) {
        void messageApi.open({ content: `You can only upload a maximum of ${maxCount} ${accept}s`, type: 'error', duration: 6 });
        setUploading(false);
        return false;
      }

      for (const file of files) {
        if (accept && file.type.split('/')[0] !== accept) {
          void messageApi.open({
            content: `${file.name} has an invalid type of - "${file.type.split('/')[1]}", only ${accept}s are accepted.`,
            type: 'error',
            duration: 6,
          });
          continue;
        }

        const base64 = await getBase64(file);

        list.push({
          name: file.name,
          url: base64,
          type: file.type,
        });
      }

      setFileList([...fileList, ...list]);
      setUploading(false);
      return false;
    },
    fileList,
    onPreview: file => {
      void (async () => {
        await handlePreview(file);
      })();
    },
    listType,
    accept: `${accept}/*`,
    multiple,
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />} disabled={disabled} loading={uploading}>
      Upload {accept === 'image' ? 'Pictures' : 'Attachment'} {maxCount ? `(maximum ${maxCount} items)` : ''}
    </Button>
  );

  return (
    <>
      {contextHolder}
      <Upload {...props}>{uploadButton}</Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default Uploader;
