import { accessDenied, bugFixing, pageNotFound, signIn, timeManagement } from 'assets/svg';
import { ErrorStatus, ErrorMessage } from './constants';

export const getErrorDetails = (status: ErrorStatus): any => {
  const getValues = (icon: any, message: ErrorMessage): any => ({
    appIcon: icon,
    message,
  });
  switch (status) {
    case ErrorStatus.NOT_FOUND:
      return getValues(pageNotFound, ErrorMessage.NOT_FOUND);
    case ErrorStatus.UNAUTHORIZED:
      return getValues(accessDenied, ErrorMessage.UNAUTHORIZED);
    case ErrorStatus.UNAUTHENTICATED:
      return getValues(signIn, ErrorMessage.UNAUTHENTICATED);
    case ErrorStatus.BOOKING_CLOSED:
      return getValues(timeManagement, ErrorMessage.BOOKING_CLOSED);
    case ErrorStatus.ERROR:
      return getValues(bugFixing, ErrorMessage.ERROR);
    default:
      return pageNotFound;
  }
};
