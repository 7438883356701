import { AppLoader, ErrorPage, ErrorStatus, Spinner } from 'components';
import { FC, ReactElement, ReactNode, Suspense } from 'react';
import { useAppSelector } from 'redux/utils/redux.hooks';
import { RootState } from 'redux/utils/redux.types';

interface Props {
  element: ReactNode | ReactElement;
  isProtected?: boolean;
  spinner?: boolean;
}

const SuspendElement: FC<Props> = ({ element, isProtected, spinner }): ReactElement => {
  const isLoggedIn = useAppSelector((state: RootState) => state.appState.isLoggedIn);

  const loader = spinner ? <Spinner /> : <AppLoader />;

  if (isProtected) {
    if (isLoggedIn) {
      return <Suspense fallback={loader}>{element}</Suspense>;
    }
    return <ErrorPage status={ErrorStatus.UNAUTHENTICATED} />;
  }

  return <Suspense fallback={loader}>{element}</Suspense>;
};

export { SuspendElement };
