import 'config/firebase.config';
import ReactDOM from 'react-dom/client';
import App from './App';

const target = ReactDOM.createRoot(document.getElementById('braidsbytifeh') as HTMLElement);
target.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
