import { urls } from 'global-constants';
import { lazy } from 'react';
import { SuspendElement } from 'routes/SuspendElement';

const ShopPage = lazy(async () => await import('./ShopPage'));
const ShopCategoryDetails = lazy(async () => await import('./ShopCategoryDetailsPage'));

const shopRoutes = [
  {
    path: urls.client.shop.root,
    element: <SuspendElement element={<ShopPage />} />,
  },
  {
    path: urls.client.shop.details,
    element: <SuspendElement element={<ShopCategoryDetails />} />,
  },
];

export default shopRoutes;
