import { FC } from 'react';
import styles from './index.module.scss';
import { Divider, Input, Typography } from 'antd';
import { ISearchTitleProps } from './types';
import _ from 'lodash';

const { Title } = Typography;

const SearchTitle: FC<ISearchTitleProps> = ({ input, title = {}, text }) => {
  const debouncedOnChange = input.onChange ? _.debounce(input.onChange, 500) : undefined;

  const inputProps = {
    ...input,
    onChange: debouncedOnChange,
  };

  return (
    <>
      <div className={styles.container}>
        <Title className={styles.container__title} {...title}>
          {text}
        </Title>
        <Input placeholder="Search" className={styles.container__input} {...inputProps} />
      </div>
      <Divider type="horizontal" />
    </>
  );
};

export default SearchTitle;
