import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { SuspendElement } from '../../routes/SuspendElement';
import { urls } from '../../global-constants';
import { ErrorPage, ErrorStatus } from 'components';
import shopRoutes from './Shop';
import serviceRoutes from './Services';
import appointmentRoutes from './Appointments';
import galleryRoutes from './Gallery';
import appSettingsRoutes from './AppSettings';

const Root = lazy(async () => await import('../../layout/admin/Root'));
const Dashboard = lazy(async () => await import('./Dashboard/Dashboard'));
const UserList = lazy(async () => await import('./Users/UserList'));
const UserDetails = lazy(async () => await import('./Users/Details'));
const ProfileDetails = lazy(async () => await import('./Profile'));

// TODO: move routes into index.tsx of each folder

const adminRoutes: RouteObject[] = [
  {
    path: urls.admin.root,
    element: <SuspendElement element={<Root />} isProtected />,
    children: [
      {
        path: urls.admin.dashboard,
        element: <SuspendElement element={<Dashboard />} />,
      },
      {
        path: urls.admin.users.root,
        element: <SuspendElement element={<UserList />} />,
      },
      {
        path: urls.admin.users.details,
        element: <SuspendElement element={<UserDetails />} spinner />,
      },
      {
        path: urls.admin.profile.root,
        element: <SuspendElement element={<ProfileDetails />} spinner />,
      },
      ...appointmentRoutes,
      ...serviceRoutes,
      ...shopRoutes,
      ...galleryRoutes,
      ...appSettingsRoutes,
    ],
    errorElement: <ErrorPage status={ErrorStatus.ERROR} />,
  },
];

export default adminRoutes;
