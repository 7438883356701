import { store } from 'redux/store';
import { IBookingState } from './types';

export const loadState = (): IBookingState | undefined => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (): void => {
  try {
    const state = store.getState().bookingState;
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    console.error('Unable to save state');
  }
};

export const clearLocalStorage = (): void => {
  localStorage.removeItem('state');
};
