import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { urls } from 'global-constants';
import { signOut } from 'firebase/auth';
import { firebaseAuth } from 'config/firebase.config';

const { confirm } = Modal;

export const logout = ():void => {
  signOut(firebaseAuth)
    .then(() => (location.href = urls.client.root))
    .catch(e => console.error(e));
};

const showLogout = (): void => {
  confirm({
    title: 'Are you sure you want to logout',
    icon: <ExclamationCircleFilled />,
    onOk() {
      logout();
    },
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    centered: true,
  });
};

export default showLogout;
