import { urls } from 'global-constants';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { SuspendElement } from 'routes/SuspendElement';

const ServiceSelectPage = lazy(async () => await import('features/Appointments/Booking'));
const AppointmentBookingStepsPage = lazy(async () => await import('features/Appointments/Booking/BookingSteps'));
const AppointmentListsPage = lazy(async () => await import('features/Appointments/List'));
const AppointmentDetailsPage = lazy(async () => await import('features/Appointments/Details'));

const appointmentRoutes: RouteObject[] = [
  {
    path: urls.client.appointment.root,
    element: <SuspendElement isProtected element={<AppointmentListsPage />} />,
  },
  {
    path: urls.client.appointment.book,
    element: <SuspendElement element={<ServiceSelectPage />} />,
  },
  {
    path: urls.client.appointment.bookingSteps,
    element: <SuspendElement isProtected element={<AppointmentBookingStepsPage />} />,
  },
  {
    path: urls.client.appointment.details,
    element: <SuspendElement isProtected element={<AppointmentDetailsPage />} />,
  },
];

export default appointmentRoutes;
