import { createApi } from '@reduxjs/toolkit/query/react';
import { endpoints, axiosBaseQuery } from 'tools';
import {
  IService,
  IGetServicesParams,
  ServiceMutationResponse,
  IServiceVariationMutationResponse,
  IServiceVariation,
  ServiceVariationType,
} from './types';
import { IResponse } from '../types';

// TODO: Delete Service

export const servicesApi = createApi({
  reducerPath: 'servicesApi',
  baseQuery: axiosBaseQuery({ baseURL: endpoints.services.root }),
  tagTypes: ['Services', 'ServiceCategory', 'ServiceVariation'],
  endpoints: builder => ({
    createService: builder.mutation<ServiceMutationResponse, Partial<IService>>({
      query: body => ({
        url: '',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Services', id: 'LIST' }],
    }),
    editService: builder.mutation<ServiceMutationResponse, IService>({
      query: body => ({
        url: '',
        method: 'PATCH',
        data: body,
        params: {
          id: body._id,
        },
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Services', id: args.category?._id },
        { type: 'Services', id: 'LIST' },
      ],
    }),
    getServices: builder.query<IService[], IGetServicesParams>({
      query: ({ type = 'service', ...rest }) => ({
        url: '',
        method: 'GET',
        params: { type, ...rest },
      }),
      providesTags: [{ type: 'Services', id: 'LIST' }],
    }),
    getService: builder.query<IService, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [{ type: 'Services', id: args.id }],
    }),
    deleteService: builder.mutation<IResponse, { id: string }>({
      query: ({ id }) => ({
        url: '',
        method: 'DELETE',
        params: {
          id,
        },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Services' as const, id },
        { type: 'Services', id: 'LIST' },
      ],
    }),
    createServiceVariation: builder.mutation<
      IServiceVariationMutationResponse,
      { serviceId?: string } & Partial<IServiceVariation>
    >({
      query: body => ({
        url: endpoints.services.variation,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'ServiceVariation', id: args.serviceId },
        { type: 'ServiceVariation', id: args.type },
      ],
    }),
    getServiceVariations: builder.query<Record<string, IServiceVariation>, { serviceId: string; type: ServiceVariationType }>({
      query: ({ serviceId, type }) => ({
        url: endpoints.services.variation,
        method: 'GET',
        params: {
          serviceId,
          type,
        },
      }),
      providesTags: (_result, _error, args) => [
        { type: 'ServiceVariation', id: args.serviceId },
        { type: 'ServiceVariation', id: args.type },
      ],
    }),
    getServiceVariation: builder.query<IServiceVariation, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.services.variation}/${id}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [{ type: 'ServiceVariation', id: args.id }],
    }),
    editServiceVariation: builder.mutation<IServiceVariationMutationResponse, Partial<IServiceVariation>>({
      query: ({ name, price, maxDuration, minDuration, visible, _id, requiredProductQuantity }) => ({
        url: endpoints.services.variation,
        method: 'PATCH',
        data: {
          name,
          price,
          maxDuration,
          minDuration,
          visible,
          requiredProductQuantity,
          _id,
        },
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'ServiceVariation', id: args._id },
        { type: 'ServiceVariation', id: args.type },
        { type: 'ServiceVariation', id: args.service?._id },
      ],
    }),
    deleteServiceVariation: builder.mutation<IResponse, { id: string; type: ServiceVariationType; serviceId: string }>({
      query: ({ id }) => ({
        url: `${endpoints.services.variation}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'ServiceVariation', id: args.id },
        { type: 'ServiceVariation', id: args.type },
        { type: 'ServiceVariation', id: args.serviceId },
      ],
    }),
  }),
});

export const {
  useCreateServiceMutation,
  useGetServicesQuery,
  useGetServiceQuery,
  useEditServiceMutation,
  useDeleteServiceMutation,
  useCreateServiceVariationMutation,
  useGetServiceVariationsQuery,
  useGetServiceVariationQuery,
  useEditServiceVariationMutation,
  useDeleteServiceVariationMutation,
} = servicesApi;
