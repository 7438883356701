import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_URL } from './constants';
import { getBearer } from './functions';
import { isAdminPath } from 'utils/isAdminPath';

const axiosBaseQuery =
  ({
    baseURL,
  }: {
    baseURL: string;
  }): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    const app = isAdminPath() ? 'admin' : 'client';
    const authorization = await getBearer();
    try {
      const result = await axios({
        url: BACKEND_URL + baseURL + url,
        method,
        data,
        params: {
          app,
          ...params,
        },
        withCredentials: true,
        headers: { Authorization: authorization },
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data ?? err.message,
        },
      };
    }
  };

export { axiosBaseQuery };
