import { urls } from 'global-constants';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { SuspendElement } from 'routes/SuspendElement';

const GalleryGrid = lazy(async () => await import('features/Gallery'));

const galleryRoutes: RouteObject[] = [
  {
    path: urls.admin.gallery.root,
    element: <SuspendElement element={<GalleryGrid />} />,
  },
];

export default galleryRoutes;
