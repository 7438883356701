import { payOnline } from 'assets/svg/payOnline';
import styles from './AppLoader.module.scss';
import Wrapper from './Wrapper';
import { FC } from 'react';

const PaymentLoader: FC = () => {
  return (
    <Wrapper>
      <div className={styles['app-loader-logo']}>{payOnline}</div>
    </Wrapper>
  );
};

export default PaymentLoader;
