import { urls } from 'global-constants';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { SuspendElement } from 'routes/SuspendElement';

const AppSettings = lazy(async () => await import('features/AppSettings'));
const HomePageSettings = lazy(async () => await import('features/AppSettings/HomePageSettings'));
const AppointmentSettings = lazy(async () => await import('features/AppSettings/AppointmentSettings'));

const appSettingsRoutes: RouteObject[] = [
  {
    path: urls.admin.appSettings.root,
    element: <SuspendElement element={<AppSettings />} />,
  },
  {
    path: urls.admin.appSettings.homePage,
    element: <SuspendElement element={<HomePageSettings />} />,
  },
  {
    path: urls.admin.appSettings.appointments,
    element: <SuspendElement element={<AppointmentSettings />} />,
  },
];

export default appSettingsRoutes;
