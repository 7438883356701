import { FC } from 'react';
import { Typography } from 'antd';
import styles from './floatingTitlePanel.module.scss';

const { Title } = Typography;

interface IFloatingPanel {
  title: string;
}

const FloatingTitlePanel: FC<IFloatingPanel> = ({ title }) => {
  return (
    <div className={styles.container}>
      <Title>{title}</Title>
    </div>
  );
};

export default FloatingTitlePanel;
