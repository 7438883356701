import { urls } from 'global-constants';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { SuspendElement } from 'routes/SuspendElement';

const SchedulePage = lazy(async () => await import('features/Appointments/Schedule'));
const HairTexture = lazy(async () => await import('features/Appointments/HairTexture'));
const AppointmentTable = lazy(async () => await import('features/Appointments/Table'));
const AppointmentDetailsPage = lazy(async () => await import('features/Appointments/Details'));

const appointmentRoutes: RouteObject[] = [
  {
    path: urls.admin.appointments.root,
    element: <SuspendElement element={<AppointmentTable />} />,
  },
  {
    path: urls.admin.appointments.hairTexture,
    element: <SuspendElement element={<HairTexture />} />,
  },
  {
    path: urls.admin.appointments.schedule,
    element: <SuspendElement element={<SchedulePage />} />,
  },
  {
    path: urls.admin.appointments.details,
    element: <SuspendElement element={<AppointmentDetailsPage />} />,
  },
];

export default appointmentRoutes;
