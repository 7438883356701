import AppLogo from 'components/AppLogo';
import { FC } from 'react';
import styles from '../AppLoader.module.scss';
import Wrapper from '../Wrapper';

const AppLoader: FC = () => {
  return (
    <Wrapper>
      <div className={styles['app-loader-logo']}>
        <AppLogo />
      </div>
    </Wrapper>
  );
};

export default AppLoader;
