import { RouterProvider } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';
import router from 'routes/Routes';
import './stylesheets';
import { AppLoader, ToggleThemeButton } from 'components';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/utils/redux.hooks';
import { RootState } from 'redux/utils/redux.types';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { updateLoggedInState } from 'redux/reducers/app/appState';
import { useLazyGetLoggedInUserQuery } from 'redux/api/users/users.api';

const AppConfig: FC = () => {
  const appTheme = useAppSelector((state: RootState) => state.appState.theme);
  const [getLoggedInUser] = useLazyGetLoggedInUserQuery();

  const { defaultAlgorithm, darkAlgorithm } = theme;
  const currentAlgorithm = appTheme === 'light' ? defaultAlgorithm : darkAlgorithm;

  const [loader, setLoader] = useState(true);

  const dispatch = useAppDispatch();
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      setLoader(true);
      dispatch(updateLoggedInState(!!user));
      if (user) {
        getLoggedInUser(null)
          .unwrap()
          .then()
          .catch()
          .finally(() => setLoader(false));
      } else {
        setLoader(false);
      }
    });
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#fb6b90',
          fontFamily: 'EB Garamond',
          borderRadius: 5,
        },
        algorithm: [currentAlgorithm],
      }}>
      {loader ? <AppLoader /> : <RouterProvider router={router} />}
      <ToggleThemeButton />
    </ConfigProvider>
  );
};

export default AppConfig;
