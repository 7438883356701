import { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const Container: FC<IProps> = ({ children, ...rest }) => {
  const { className, ...props } = rest;

  const classes = classnames(styles.container, className);

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

export default Container;
