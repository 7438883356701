import { FloatButton } from 'antd';
import { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';

interface TopFloatButtonProps {
  icon?: ReactNode;
  onClick: () => void;
}

const TopFloatButton: FC<TopFloatButtonProps> = ({ icon = <PlusOutlined />, onClick }) => {
  return <FloatButton icon={icon} type="primary" className={styles.floatButton} onClick={onClick} />;
};

export default TopFloatButton;
