import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IProductCategory,
  IProductSubCategory,
  ProductCategoryMutationResponse,
  ProductSubCategoryMutationResponse,
} from './types';
import { endpoints, axiosBaseQuery } from 'tools';

export const productCategoriesApi = createApi({
  reducerPath: 'productCategoriesApi',
  baseQuery: axiosBaseQuery({ baseURL: endpoints.productCategories.root }),
  tagTypes: ['ProductCategory', 'ProductSubCategory'],
  endpoints: builder => ({
    getProductCategories: builder.query<
      Record<string, Pick<IProductCategory, 'name' | 'nameId' | 'thumbnail' | 'visible' | '_id'>>,
      null
    >({
      query: () => {
        return {
          url: '',
          method: 'get',
        };
      },
      providesTags: [{ type: 'ProductCategory', id: 'LIST' }],
    }),
    getProductCategory: builder.query<IProductCategory, { id?: string; nameId?: string }>({
      query: ({ id = '', nameId = '' }) => {
        return {
          url: endpoints.productCategories.getCategory,
          method: 'get',
          params: { id, nameId },
        };
      },
      providesTags: (result, _error) => [
        result ? { type: 'ProductCategory', id: result._id } : { type: 'ProductCategory', id: 'category' },
        'ProductCategory',
      ],
    }),
    createProductCategory: builder.mutation<ProductCategoryMutationResponse, Partial<IProductCategory>>({
      query: body => {
        return {
          url: '',
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: [{ type: 'ProductCategory', id: 'LIST' }],
    }),
    editProductCategory: builder.mutation<ProductCategoryMutationResponse, { body: Partial<IProductCategory>; id: string }>({
      query: ({ body, id }) => {
        return {
          url: '',
          method: 'PATCH',
          data: body,
          params: { id },
        };
      },
      invalidatesTags: (result, _error) => [
        {
          type: 'ProductCategory',
          id: 'LIST',
        },
        { type: 'ProductCategory', id: result?.productCategory._id },
      ],
    }),
    deleteProductCategory: builder.mutation<{ message: string }, string>({
      query: id => ({
        url: '',
        method: 'DELETE',
        params: {
          id,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'ProductCategory', id: 'LIST' },
        { type: 'ProductCategory', id: arg },
      ],
    }),

    createProductSubCategory: builder.mutation<ProductSubCategoryMutationResponse, { name: string }>({
      query: body => {
        return {
          url: endpoints.productCategories.subcategories,
          method: 'POST',
          data: body,
        };
      },
      invalidatesTags: [
        {
          type: 'ProductSubCategory',
          id: 'LIST',
        },
      ],
    }),
    editProductSubCategory: builder.mutation<ProductSubCategoryMutationResponse, { name: string; id: string }>({
      query: body => {
        return {
          url: endpoints.productCategories.subcategories,
          method: 'PATCH',
          data: body,
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        {
          type: 'ProductSubCategory',
          id: arg.id,
        },
      ],
    }),
    getProductSubCategories: builder.query<IProductSubCategory[], null>({
      query: () => {
        return {
          url: endpoints.productCategories.subcategories,
          method: 'get',
        };
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ _id }): { type: 'ProductSubCategory'; id: string } => ({ type: 'ProductSubCategory', id: _id })),
              { type: 'ProductSubCategory', id: 'LIST' },
            ]
          : [{ type: 'ProductSubCategory', id: 'LIST' }],
    }),
    deleteProductSubCategory: builder.mutation<{ message: string }, string>({
      query: id => ({
        url: endpoints.productCategories.subcategories,
        method: 'DELETE',
        params: {
          id,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'ProductSubCategory', id: 'LIST' },
        { type: 'ProductSubCategory', id: arg },
      ],
    }),
  }),
});

export const {
  useCreateProductCategoryMutation,
  useGetProductCategoriesQuery,
  useGetProductCategoryQuery,
  useEditProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useCreateProductSubCategoryMutation,
  useEditProductSubCategoryMutation,
  useGetProductSubCategoriesQuery,
  useDeleteProductSubCategoryMutation,
} = productCategoriesApi;
