import { Typography } from 'antd';
import { FC } from 'react';

const { Title } = Typography;

const ModalTitle: FC<{ text: string }> = ({ text }) => {
  return <Title level={3}>{text}</Title>;
};

export default ModalTitle;
