import { Button, Form, Input, Typography } from 'antd';
import { FC, useState, ReactElement } from 'react';
import { sendLoginEmail } from '../functions';
import AuthResult from '../AuthResult/AuthResult';
import { IFeedback, IFormProps } from '../types';
import { useLazyIsRegisteredQuery } from 'redux/api/users/users.api';

const { Text } = Typography;

const Login: FC<IFormProps> = ({ toggleForm }) => {
  const [submitted, setSubmitted] = useState(false);
  const [isRegistered, { isFetching }] = useLazyIsRegisteredQuery();

  const [feedback, setFeedback] = useState<IFeedback>({ status: 200 });

  const getExtras = (status: 404 | 500): ReactElement | undefined => {
    if (status === 404) {
      return (
        <Button onClick={toggleForm} disabled={isFetching}>
          Sign up
        </Button>
      );
    }
    return undefined;
  };

  const onFinish = ({ email }: { email: string }): void => {
    isRegistered(email)
      .unwrap()
      .then(() => {
        sendLoginEmail({ email })
          .then(() => setFeedback({ status: 200 }))
          .catch(() => setFeedback({ status: 500 }));
      })
      .catch((e: { status: 404 | 500 }) =>
        setFeedback({
          status: e.status,
          extras: getExtras(e.status),
        }),
      )
      .finally(() => setSubmitted(true));
  };

  if (submitted) {
    return <AuthResult status={feedback.status} extras={feedback.extras} />;
  }

  return (
    <Form
      data-testid="loginForm"
      name="login"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="on">
      <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email address!' }]}>
        <Input type="email" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={isFetching}>
          Submit
        </Button>
      </Form.Item>

      <Form.Item>
        <Text>
          Don&apos;t have an account?{' '}
          <Button type="text" onClick={toggleForm} disabled={isFetching}>
            Sign up.
          </Button>
        </Text>
      </Form.Item>
    </Form>
  );
};

export default Login;
