import { urls } from 'global-constants';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { SuspendElement } from 'routes/SuspendElement';

const ServiceCategories = lazy(async () => await import('./ServiceCategories'));
const ServiceCategoriesDetails = lazy(async () => await import('./ServiceCategoriesDetails'));
const ServiceListPage = lazy(async () => await import('./ServiceListPage'));
const ServiceDetailsPage = lazy(async () => await import('features/Services/ServiceDetails'));
const ServiceExtrasPage = lazy(async () => await import('features/Services/ServiceExtras/List'));

const serviceRoutes: RouteObject[] = [
  {
    path: urls.admin.services.root,
    element: <SuspendElement element={<ServiceCategories />} />,
  },
  {
    path: urls.admin.services.services,
    element: <SuspendElement element={<ServiceListPage />} />,
  },
  {
    path: urls.admin.services.categoryDetails,
    element: <SuspendElement element={<ServiceCategoriesDetails />} />,
  },
  {
    path: urls.admin.services.serviceDetails,
    element: <SuspendElement element={<ServiceDetailsPage />} />,
  },
  {
    path: urls.admin.services.extras,
    element: <SuspendElement element={<ServiceExtrasPage />} />,
  },
];

export default serviceRoutes;
