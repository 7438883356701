import { ReactNode } from 'react';
import { useGetLoggedInUserQuery } from 'redux/api/users/users.api';
import { ErrorPage, ErrorStatus, Container } from 'components';
import { isAdminPath } from 'utils/isAdminPath';
import { useAppSelector } from 'redux/utils/redux.hooks';
import { RootState } from 'redux/utils/redux.types';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  permission?: string;
  fullScreen?: boolean;
  container?: boolean;
}

const HasAccess = ({ children, permission, fullScreen, container, ...rest }: IProps): JSX.Element | null => {
  const isLoggedIn = useAppSelector((state: RootState) => state.appState.isLoggedIn);
  const { data } = useGetLoggedInUserQuery(null, { skip: !isLoggedIn });

  if (!data) {
    return null;
  }

  if (!isAdminPath()) {
    return null;
  }

  // Simplifying permissions till it's properly implemented across all environments
  // if (!permission || data?.permissions.find(perm => perm.permission === permission)) {
  if (data?.role === 'admin') {
    return container ? <Container {...rest}>{children}</Container> : <div {...rest}>{children}</div>;
  }

  if (fullScreen) {
    return <ErrorPage status={ErrorStatus.UNAUTHORIZED} />;
  }

  return null;
};

export default HasAccess;
