export const payOnline = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="100%"
    viewBox="0 0 619.86836 562.03084"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect x="60.40639" y="139.84921" width="280.48127" height="277.59048" rx="26.74409" fill="#f2f2f2" />
    <path
      d="M424.884,314.33363a68.91168,68.91168,0,0,0-68.91167,68.91167v176.435a21.24414,21.24414,0,0,0,21.24414,21.24414H522.08892A103.36435,103.36435,0,0,0,625.45327,477.5601V335.57777a21.24415,21.24415,0,0,0-21.24414-21.24414Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#fff"
    />
    <path
      d="M877.7568,217.21468H311.02633a1,1,0,0,1,0-2H877.7568a1,1,0,0,1,0,2Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <ellipse cx="49.31535" cy="9.56458" rx="8.45661" ry="8.64507" fill="#e6e6e6" />
    <ellipse cx="78.5291" cy="9.56458" rx="8.45661" ry="8.64507" fill="#e6e6e6" />
    <ellipse cx="107.74285" cy="9.56458" rx="8.45661" ry="8.64507" fill="#e6e6e6" />
    <path
      d="M431.12009,185.382a.9967.9967,0,0,1-.71484-.30078l-5.70605-5.833a.99951.99951,0,0,1,0-1.39843l5.70605-5.83252a1,1,0,1,1,1.42969,1.39843l-5.022,5.1333,5.022,5.13379a1,1,0,0,1-.71485,1.69922Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M440.70847,185.382a1,1,0,0,1-.71484-1.69922l5.02148-5.13379-5.02148-5.1333a1,1,0,1,1,1.42969-1.39843l5.70556,5.83252a.99953.99953,0,0,1,0,1.39843l-5.70556,5.833A.9967.9967,0,0,1,440.70847,185.382Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M827.2183,170.905h-10.61a2.18934,2.18934,0,0,0-2.19,2.19v10.62a2.18934,2.18934,0,0,0,2.19,2.19h10.61a2.1979,2.1979,0,0,0,2.2-2.19v-10.62A2.1979,2.1979,0,0,0,827.2183,170.905Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M801.2183,170.905h-10.61a2.18934,2.18934,0,0,0-2.19,2.19v10.62a2.18934,2.18934,0,0,0,2.19,2.19h10.61a2.1979,2.1979,0,0,0,2.2-2.19v-10.62A2.1979,2.1979,0,0,0,801.2183,170.905Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M851.7183,171.405h-10.61a2.18934,2.18934,0,0,0-2.19,2.19v10.62a2.18934,2.18934,0,0,0,2.19,2.19h10.61a2.1979,2.1979,0,0,0,2.2-2.19v-10.62A2.1979,2.1979,0,0,0,851.7183,171.405Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M638.94274,186.98458h-69a9,9,0,0,1,0-18h69a9,9,0,0,1,0,18Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M525.3338,486.38416H371.34105a3.32086,3.32086,0,1,1,0-6.64172H525.3338a3.32086,3.32086,0,0,1,0,6.64172Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#f2f2f2"
    />
    <path
      d="M525.3338,391.66156H293.38721a3.32086,3.32086,0,1,1,0-6.64171H525.3338a3.32086,3.32086,0,0,1,0,6.64171Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#f2f2f2"
    />
    <path
      d="M525.3383,415.3753H293.382a3.32344,3.32344,0,0,0,0,6.64687H525.3383a3.32344,3.32344,0,0,0,0-6.64687Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#f2f2f2"
    />
    <path
      d="M525.3338,452.38245H293.38721a3.32086,3.32086,0,1,1,0-6.64172H525.3338a3.32086,3.32086,0,0,1,0,6.64172Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#f2f2f2"
    />
    <polygon points="430.582 548.714 419.933 548.713 414.868 507.64 430.584 507.641 430.582 548.714" fill="#a0616a" />
    <path
      d="M721.02239,728.80744l-32.74574-.00125v-.41408a12.74625,12.74625,0,0,1,12.74555-12.74536h.00079l5.98141-4.53779,11.16,4.53851,2.85851.00007Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#2f2e41"
    />
    <polygon points="537.725 548.714 527.077 548.713 522.011 507.64 537.728 507.641 537.725 548.714" fill="#a0616a" />
    <path
      d="M828.16609,728.80744l-32.74574-.00125v-.41408a12.74625,12.74625,0,0,1,12.74555-12.74536h.00079l5.98141-4.53779,11.16,4.53851,2.8585.00007Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#2f2e41"
    />
    <path
      d="M729.33579,481.73279s-6.99338,7.42211-7.28869,12.23732,3.349-3.93121-.29532,4.81522-3.64434,2.91547-3.64434,8.74642-7.72224,11.52478-7.72224,11.52478L697.651,571.449l7.3368,122.67319h16.03512c14.90172-45.42466,21.83474-80.503,13.8485-96.21067l22.2685-49.4952S799.21831,680.086,800.93726,681.63758s1.71895-.63508,1.71895,1.55153-2.18661,0,0,2.1866l10.20416,10.20416,15.30624-1.45773s2.04837-95.33-15.01092-111.80547c0,0,3.93966-51.01877,0-50.87551s-3.93966,6.70308-3.93966.14326,4.66853-8.31021,4.66853-8.31021L800.76492,477.197Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#2f2e41"
    />
    <path
      d="M811.95805,380.16959l-14.82,62.05,3.5,23.08,4.2,27.65-14.52-.94-22.47-1.46-41.72-2.7-3.65-122.45,19.14-20,3.42-9.71,27.67-.17,6.62,11.66,17.02,4.84a42.21587,42.21587,0,0,1,6.14,4.74c4.2,3.89,7.92,8.83,9.66,13.89a18.26132,18.26132,0,0,1,.99,4.57Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M683.16043,537.60686a8.7959,8.7959,0,0,0,2.66491-13.12821l21.10689-38.4259-9.19319-8.45131-24.73528,45.82233a8.78357,8.78357,0,0,0,10.15667,14.18309Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M734.6398,357.91347l-4.91986,88.1348L706.01713,501.435l-15.42727,20.45876,1.75369-9.27062-10.63419-5.32074-.99855-.49563,20.41561-79.228,1.88048-30.78742A38.36336,38.36336,0,0,1,717.10322,369.364l11.80767-9.59191Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M683.16043,537.60686a8.7959,8.7959,0,0,0,2.66491-13.12821l21.10689-38.4259-9.19319-8.45131-24.73528,45.82233a8.78357,8.78357,0,0,0,10.15667,14.18309Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#a0616a"
    />
    <path
      d="M734.6398,357.91347l-4.91986,88.1348L706.01713,501.435l-15.42727,20.45876,1.75369-9.27062-10.63419-5.32074-.99855-.49563,20.41561-79.228,1.88048-30.78742A38.36336,38.36336,0,0,1,717.10322,369.364l11.80767-9.59191Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <rect x="213.68198" y="191.96732" width="280.48127" height="170.59048" rx="26.74409" fill="#fff" />
    <path
      d="M757.48526,532.54255H530.4921A27.77566,27.77566,0,0,1,502.748,504.79841V387.69587a27.77566,27.77566,0,0,1,27.74414-27.74414H757.48526a27.77556,27.77556,0,0,1,27.74365,27.74414V504.79841A27.77556,27.77556,0,0,1,757.48526,532.54255ZM530.4921,361.95173A25.74413,25.74413,0,0,0,504.748,387.69587v43.76968a99.077,99.077,0,0,0,99.077,99.077h153.6603a25.74365,25.74365,0,0,0,25.74365-25.74365v-117.103a25.74413,25.74413,0,0,0-25.74414-25.74414Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#3f3d56"
    />
    <rect x="243.92262" y="316.26256" width="60" height="7" rx="3.5" fill="#fb6b90" />
    <rect x="419.92262" y="306.76256" width="44" height="26" rx="3.5" fill="#fb6b90" />
    <circle cx="261.92262" cy="239.76256" r="18" fill="#fb6b90" />
    <circle cx="467.79653" cy="138.77976" r="22.60167" fill="#a0616a" />
    <path
      d="M776.40434,319.44653c.17634,1.35028-2.26629,1.493-2.81707.24763s.16361-2.65684.85821-3.82811l1.61227-2.71869a3.73532,3.73532,0,0,0-2.4605-5.56408h0c1.47528-3.318-.12574-7.4594-3.06517-9.59131s-7.313,1.857-10.87911,2.54107-6.42119-2.04529-9.67189-.42719l1.953-4.50218-10.81018,4.65749,2.5115-5.06459c-2.72828.14863-3.89,3.47146-4.43363,6.14916-.57923,2.853-2.09906,6.41945-5.20067,5.8284a4.0018,4.0018,0,0,1-3.02643-2.998c-2.39623-9.24107,4.56171-19.34369,13.78545-20.96447a13.5606,13.5606,0,0,1,25.88081,1.23229l3.0242-3.70271.58962,5.24629c2.17326-.231,4.22651,1.30332,5.28261,3.21671s.557,4.38376,1.571,6.31979c4.54466,8.677,3.041,18.44556-3.97631,25.10044"
      transform="translate(-290.06582 -168.98458)"
      fill="#2f2e41"
    />
    <path
      d="M855.67531,713.68247a2.26935,2.26935,0,0,0,1.64468-3.93333l-.1555-.61819q.03072-.07428.06179-.1484a6.09416,6.09416,0,0,1,11.24138.04177c1.83859,4.42818,4.17943,8.86389,4.7558,13.546a18.02889,18.02889,0,0,1-.31649,6.20046A72.28135,72.28135,0,0,0,879.48213,698.75a69.76616,69.76616,0,0,0-.43275-7.78294q-.3585-3.17781-.99471-6.31034a73.11893,73.11893,0,0,0-14.50012-30.98962,19.459,19.459,0,0,1-8.09295-8.39652,14.84338,14.84338,0,0,1-1.34991-4.05618c.394.05168,1.48556-5.94866,1.18841-6.31679.549-.83317,1.53177-1.24733,2.13143-2.06035,2.98232-4.0434,7.0912-3.33741,9.23621,2.15727,4.58224,2.31266,4.62659,6.14806,1.815,9.83683-1.78878,2.34682-2.03456,5.52233-3.60409,8.03478.16151.20671.32945.407.49091.61366a73.59123,73.59123,0,0,1,7.681,12.1686,30.59244,30.59244,0,0,1,1.8264-14.20959c1.74819-4.21732,5.02491-7.76915,7.91045-11.415a6.27921,6.27921,0,0,1,11.184,3.08332q.00886.08063.01731.1612-.6429.36267-1.25863.76992a3.0782,3.0782,0,0,0,1.24106,5.60174l.06277.00967a30.628,30.628,0,0,1-.80734,4.57938c3.70179,14.31579-4.29011,19.5299-15.70147,19.76412-.25191.12916-.49739.25833-.74929.38109a75.11456,75.11456,0,0,1,4.04326,19.02779,71.24332,71.24332,0,0,1-.05167,11.50322l.01938-.13562a18.8256,18.8256,0,0,1,6.42661-10.87027c4.9456-4.06264,11.93282-5.55869,17.26825-8.82426a3.533,3.533,0,0,1,5.41122,3.43708l-.02182.14261a20.67547,20.67547,0,0,0-2.31871,1.11733q-.6429.36276-1.25864.76992a3.07824,3.07824,0,0,0,1.24107,5.6018l.06282.00965c.04519.00646.084.01295.12911.01943a30.65453,30.65453,0,0,1-5.63854,8.82923c-2.31464,12.49712-12.256,13.68282-22.89023,10.04353h-.00648a75.09193,75.09193,0,0,1-5.0444,14.72621H856.12687c-.06464-.20022-.12274-.40692-.1809-.60717a20.47485,20.47485,0,0,0,4.9863-.297c-1.337-1.64059-2.674-3.2941-4.011-4.93463a1.11772,1.11772,0,0,1-.084-.09689c-.67817-.8396-1.36282-1.67283-2.041-2.51246l-.00036-.001a29.99039,29.99039,0,0,1,.87876-7.63984Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#f2f2f2"
    />
    <path
      d="M577.124,729.82542a1.18647,1.18647,0,0,0,1.19007,1.19h330.29a1.19,1.19,0,0,0,0-2.38H578.314A1.18651,1.18651,0,0,0,577.124,729.82542Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#ccc"
    />
    <path
      d="M767.48859,532.25832a8.7959,8.7959,0,0,0,2.19681-13.2146l19.72835-39.15154-9.48764-8.11935L756.83443,518.445a8.78357,8.78357,0,0,0,10.65416,13.81328Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#a0616a"
    />
    <path
      d="M812.14805,370.64957l.99,4.57-2.37,63.88-10.13,26.2-10.32,26.71-1.27,3.29-14.69,20.99,1.42-9.33-10.82-4.94-1.01-.45,3.9-11.02,8.97-25.32,3.88-10.96.84-32.61.78-30.83a38.36441,38.36441,0,0,1,13.11-27.91l7.06-6.16C806.688,360.64957,810.40806,365.58958,812.14805,370.64957Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#e6e6e6"
    />
    <path
      d="M576.138,530.232l-46.8779-1a22.02467,22.02467,0,0,1-21.99209-22l-2-49.01237C511.02409,498.178,533.14387,523.52476,576.138,530.232Z"
      transform="translate(-290.06582 -168.98458)"
      fill="#fb6b90"
    />
  </svg>
);
