const urls = {
  frontendUrl: process.env.REACT_APP_FRONTEND_URL,
  client: {
    finishAuth: '/finish-sign-in',
    root: '/',
    services: {
      root: '/hairstyles',
      categoryDetails: '/hairstyles/:nameId',
      serviceDetails: '/styles',
    },
    appointment: {
      root: '/appointments',
      book: '/book-appointment',
      bookingSteps: '/book-appointment/:id',
      details: '/appointments/details',
    },
    gallery: { root: '/gallery' },
    faq: '/faq',
    profile: {
      root: '/profile',
      address: '/profile/address',
    },
    shop: {
      root: '/shop',
      details: '/shop/:nameId',
    },
    payment: {
      root: '/payment',
    },
  },
  admin: {
    root: '/admin',
    dashboard: '',
    services: {
      root: '/admin/hairstyles',
      categoryDetails: '/admin/hairstyles/:nameId',
      services: '/admin/hairstyles/styles',
      serviceDetails: '/admin/hairstyles/styles/details',
      serviceForm: '/admin/hairstyles/styles/form',
      variations: '/admin/hairstyles/variations',
      extras: '/admin/hairstyles/extras',
    },
    users: {
      root: '/admin/users',
      details: '/admin/users/details',
    },
    profile: {
      root: '/admin/profile',
    },
    appointments: {
      root: '/admin/appointments',
      settings: '/admin/appointments/settings',
      schedule: '/admin/appointments/schedule',
      hairTexture: '/admin/appointments/hair-texture',
      details: '/admin/appointments/details',
    },
    shop: {
      root: '/admin/shop',
      products: '/admin/shop/products',
      productIndex: '/admin/shop/products/index',
      productDetails: '/admin/shop/products/details',
      subcategories: '/admin/shop/subcategories',
      details: '/admin/shop/:nameId',
      attributes: '/admin/shop/attributes',
      attributeOptions: '/admin/shop/attributes/options',
    },
    gallery: { root: '/admin/gallery' },
    appSettings: {
      root: '/admin/app-settings',
      homePage: '/admin/app-settings/home-page',
      appointments: '/admin/app-settings/appointment'
    },
  },
  notFound: '/not-found',
};

export default urls;
