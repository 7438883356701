import { Dropdown, Button } from 'antd';
import { FC } from 'react';
import { getMenuItems } from './getMenu';
import { useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import showLogout from 'features/Authentication/Logout';
import { IAccountDropDownProps } from './types';

const AccountDropDown: FC<IAccountDropDownProps> = ({ initial, isAdmin, smallScreen }) => {
  const navigate = useNavigate();

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      return showLogout();
    }
    navigate(key);
  };

  const menuProps = {
    items: getMenuItems(isAdmin),
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} trigger={['click']} destroyPopupOnHide={true}>
      <Button shape="circle" type="primary" size={smallScreen ? 'large' : undefined}>
        {initial}
      </Button>
    </Dropdown>
  );
};

export default AccountDropDown;
