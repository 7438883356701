import { Button, Modal } from 'antd';
import { FC, useState } from 'react';
import LoginForm from '../Login/LoginForm';
import RegistrationForm from '../Registration/RegistrationForm';

const LoginButton: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [formName, setFormName] = useState<'login' | 'register'>('login');

  const closeModal = (): void => {
    setShowModal(false);
    setFormName('login');
  };

  const toggleForm = (): void => {
    if (formName === 'register') {
      setFormName('login');
    } else {
      setFormName('register');
    }
  };
  return (
    <>
      <Button onClick={() => setShowModal(true)}>Sign In</Button>
      {showModal && (
        <Modal centered open={showModal} footer={null} onCancel={closeModal}>
          {formName === 'login' ? <LoginForm toggleForm={toggleForm} /> : <RegistrationForm toggleForm={toggleForm} />}
        </Modal>
      )}
    </>
  );
};

export default LoginButton;
