import { firebaseAuth } from 'config/firebase.config';
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import { urls } from 'global-constants';

const sendLoginEmail = async ({ email }: { email: string }): Promise<void> => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${urls.frontendUrl}/${urls.client.finishAuth}?location=${location.pathname}`,
    // This must be true.
    handleCodeInApp: true,
  };

  try {
    await sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);
    localStorage.setItem('emailForSignIn', email);
  } catch (e: any) {
    console.log(e);
  }
};

const signInWithLoginLink = async ({ url, email }: { url: string; email: string }): Promise<void> => {
  if (isSignInWithEmailLink(firebaseAuth, url)) {
    await signInWithEmailLink(firebaseAuth, email, url);
  }
};

export { sendLoginEmail, signInWithLoginLink };
