import { FC } from 'react';
import { Typography, Divider } from 'antd';
import styles from './displayCard.module.scss';
import _ from 'lodash';
import classNames from 'classnames';
import noImage from 'assets/no_image.jpg';
import VisibleTag from 'components/VisibleTag';
import { isAdminPath } from 'utils/isAdminPath';
import { IDisplayCardProps } from './types';
import { getPriceString } from 'utils/util.functions';

const { Text } = Typography;

/**
 * Displays services and products
 * @param {string} title ;
 * @param {string | undefined} subtitle?: string;
 * @param {string | undefined} image?: string;
 * @param {function | undefined} onClick?: () => void;
 * @param {ReactNode | ReactElement | undefined} children?: ;
 * @param {'service' | 'shop' | undefined} type?: 'service' | 'shop';
 * @param {number | undefined} price?: number;
 * @param {number | undefined} discount?: number;
 * @param {string | undefined} visible: boolean;
 *
 * */
const DisplayCard: FC<IDisplayCardProps> = ({
  title,
  image,
  visible,
  children,
  onClick,
  subtitle,
  type = 'service',
  price,
  divider = true,
  discount,
  ...rest
}) => {
  const componentClasses = classNames(
    styles.container,
    rest.className,
    type === 'service' ? styles.container__serviceAspectRatio : styles.container__shopAspectRatio,
  );

  delete rest.className;

  return (
    <div className={componentClasses} {...rest}>
      <div onClick={onClick ?? undefined}>
        <div className={type === 'service' ? styles.imageContainer : styles.shopImageContainer}>
          <img src={image ?? noImage} className={styles.image} />
        </div>

        <div className={styles.title} style={{ display: title ? 'flex' : 'none' }}>
          <Text className={styles.title__text} ellipsis={{ tooltip: true }}>
            {_.capitalize(title)}
          </Text>
          {isAdminPath() && <VisibleTag visible={visible} />}
        </div>
        {price && (
          <div className={styles.title__price}>
            <Text strong delete={!!discount} type={discount ? 'danger' : undefined}>
              {getPriceString(price)}
            </Text>{' '}
            {discount ? <Text strong>{getPriceString(price, discount)}</Text> : null}
          </div>
        )}
        {divider && <Divider className={styles.divider} />}
      </div>
      {children}
    </div>
  );
};

export default DisplayCard;
