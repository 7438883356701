import { Tag } from 'antd';
import { FC } from 'react';

const RandomColorTag: FC<{ name: string }> = ({ name }) => {
  const colors: string[] = ['red', 'blue', 'green', 'yellow', 'orange', 'purple'];

  function getRandomColor(colors: string[]): string {
    const randomIndex: number = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  const randomColor: string = getRandomColor(colors);

  return <Tag color={randomColor}>{name.toUpperCase()}</Tag>;
};

export default RandomColorTag;
